<template>
<div class="display-block">
    <h3>Customer</h3>
    <v-card>
        <v-card-text>
            <v-layout wrap>
                <v-flex xs4>
                    <row-item class="mx-3" label="FirstName : " :value="customer.firstName"></row-item>
                </v-flex>
                <v-flex xs4>
                    <row-item class="mx-2" label="MiddleName : " :value="customer.middleName"></row-item>
                </v-flex>
                <v-flex xs4>
                    <row-item class="mx-2" label="LastName : " :value="customer.lastName"></row-item>
                </v-flex>
                <v-flex xs4>
                    <row-item class="mx-2" label="Mobile No : " :value="customer.mobileNo.toString()"></row-item>
                </v-flex>
                <v-flex xs3>
                    <row-item v-if="customer.email" label="E-Mail : " :value="customer.email"></row-item>
                </v-flex>
            </v-layout>
        </v-card-text>
        <customer-order :customerId="$route.params.id"></customer-order>
        <reward-logs :rewardLogs="reward.rewardLogs" :totalRewardPoint="reward.totalRewardPoint"></reward-logs>
        <v-flex text-right>
            <v-btn @click="$router.go(-1)" class="ma-2" :style="theme">Back</v-btn>
        </v-flex>
    </v-card>
</div>
</template>

<script>
import RowItem from '@/components/RowItem'
import appConstants from '@/utils/appConstants'
import CustomerOrder from '@/components/CustomerOrder'
import RewardLogs from '@/components/RewardLogs'
export default {
    components: {
        RowItem,
        CustomerOrder,
        RewardLogs
    },
    data() {
        return {
            customer: {
                firstName: "",
                middleName: "",
                lastName: "",
                mobileNo: "",
                email: ""
            },
            reward: {
                totalRewardPoint: 0,
                rewardLogs: []
            },
            items: [],
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id
            this.customer = await this.getItem(appConstants.CUSTOMERS_API + "/" + this.id)
            let rewards = await this.getItem(appConstants.REWARD_API + "/user/" + this.id)
            if (rewards.length > 0)
                this.reward = rewards[0]
        },
    }
}
</script>

<template>
<v-container>
    <v-flex text-center>
        <progress-bar :show="fetchingData" size="70"></progress-bar>
    </v-flex>
    <h4 v-if="items.length>0" :style="themeInverted">Order Statistics</h4>
    <v-layout v-if="!fetchingData&&items.length>0">
        <v-flex sm2 xs4 text-right>
            <row-item label="Total Business" :value="'₹ '+totalBusiness.toFixed(2)"></row-item>
        </v-flex>
        <v-flex sm6 text-right>
            <row-item label="Total Credit" :value="'₹ '+totalCredit"></row-item>
        </v-flex>
        <v-flex sm6 text-right>
            <row-item label="Total Orders" :value="totalOrders"></row-item>
        </v-flex>
        <v-flex sm4></v-flex>
    </v-layout>
    <h4 v-if="items.length>0" :style="themeInverted">Order Details</h4>
    <v-data-table @click:row="navigate" v-if="!fetchingData&&items.length>0" hide-default-footer :items-per-page="items.length"
        :headers="headers" :items="items" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" width='50%' class="elevation-1">
        <template v-slot:item.date="{item}" v-if="$vuetify.breakpoint.smAndUp">
            <label>{{ item.date | dateTimeFormat }}</label>
        </template>
        <template v-slot:item.statusLog="{item}" v-if="$vuetify.breakpoint.smAndUp">
            <order-status :status="item.currentStatus"></order-status>
        </template>
        <template v-slot:item="{item}" v-if="$vuetify.breakpoint.xsOnly">
            <order-card :order="item"></order-card>
        </template>
    </v-data-table>
    <v-flex v-else text-center>
        <v-alert outlined color="grey lighten-1" icon="mdi-receipt" prominent>
            <h1>No previous order found</h1>
        </v-alert>
    </v-flex>
    <pagination v-if="show" @input="initComponent()" :changeRows="false" :api="apiCall" v-model="items" sort="-orderNo" :apiParams="`customer=${customerId}`"></pagination>
</v-container>
</template>

<script>
import appConstants from "@/utils/appConstants";
import OrderCard from '@/components/OrderResponsiveCard'
export default {
    components: {
        OrderCard,
    },
    props: {
        customerId: {
            type: String
        },
    },
    data() {
        return {
            headers: [{
                    text: "Order Number",
                    align: "start",
                    value: "orderNo"
                },
                {
                    text: "Order Date",
                    align: "start",
                    value: "date"
                },
                {
                    text: "Customer",
                    value: "customer.displayName",
                    sortable: false
                },
                {
                    text: "Amount",
                    value: "total",
                    align: "right"
                },
                {
                    text: "Items",
                    value: "items.length",
                    align: "right",
                    sortable: this.$vuetify.breakpoint.smAndUp
                },
                {
                    text: "Payment Status",
                    value: "pStatus",
                    align: "right",
                    sortable: this.$vuetify.breakpoint.smAndUp
                },
                {
                    text: "Delivery Status",
                    value: "statusLog",
                    align: "right"
                }
            ],
            sortBy: 'date',
            sortDesc: true,
            items: [],
            fetchingData: false,
            totalBusiness: 0,
            totalCredit: 0,
            totalOrders: 0,
            apiCall:appConstants.ORDERS_API,
            show:true
        }
    },
    methods: {
        initComponent(){
            if(this.items.length==0)
                this.show=false
            this.items.forEach(rec => {
                    this.totalBusiness = this.totalBusiness + rec.total
                    if (rec.balance)
                        this.totalCredit = this.totalCredit + rec.balance
                });
                this.totalOrders = this.items.length

        },
        navigate(order){
            this.$router.push("/app/orders/view/"+order._id)
        }
    },
}
</script>

<style lang="scss" scoped>
h4{
    padding: 0 1%;
    margin: 1%;
    font-weight: 400;
}
</style>
